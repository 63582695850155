import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

const NoRowsOverlay = ({ type }) => (
  <Stack height="3rem" alignItems="center" justifyContent="center">
    No {type} to show
  </Stack>
);

NoRowsOverlay.propTypes = {
  type: PropTypes.string,
};

NoRowsOverlay.defaultProps = {
  type: 'rows',
};

export default NoRowsOverlay;
