import PageContent from '../../components/PageContent';
import Invoices from '../../components/Invoices';

const ReportingPage = () => (
  <PageContent title="Reporting" testId="reporting-page">
    <Invoices />
  </PageContent>
);

export default ReportingPage;
