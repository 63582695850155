import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { Box, Container, Typography } from '@mui/material';

import { APP_NAME } from '../../constants';

const PageContent = ({
  children,
  fullWidth,
  title,
  preTitle,
  testId,
  ...props
}) => (
  <Box
    component={fullWidth ? Box : Container}
    data-testid={testId}
    {...props}
    sx={{ my: 2, px: fullWidth ? 3 : 0 }}
    maxWidth={!fullWidth ? 'lg' : 'auto'}
  >
    <Helmet>
      <title>
        {title}
        {title && ' • '}
        {APP_NAME}
      </title>
    </Helmet>
    {title && (
      <Typography
        variant="h4"
        component="h1"
        sx={{ float: 'left', mb: '2rem' }}
      >
        {preTitle && (
          <Typography component="div">{preTitle.toUpperCase()}</Typography>
        )}
        {title}
      </Typography>
    )}

    {children}
  </Box>
);

PageContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  fullWidth: PropTypes.bool,
  preTitle: PropTypes.string,
  testId: PropTypes.string,
  title: PropTypes.string,
};

PageContent.defaultProps = {
  children: null,
  fullWidth: false,
  testId: 'page-content',
  title: '',
  preTitle: '',
};

export default PageContent;
