import { useReducer, useState } from 'react';
import { format } from 'date-fns';
import { Button } from '@mui/material';

import Form from '../Form';
import Loading from '../Loading';
import { URL_DATE_FORMAT } from '../../constants';

import { fetchInvoices } from '../../api/reporting';

const formReducer = (state, { field, payload }) => ({
  ...state,
  [field]: payload,
});

const Invoices = () => {
  const now = new Date();
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(formReducer, {
    from: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
    to: new Date(),
  });

  const { from, to } = state;

  const handleDownload = async () => {
    setLoading(true);
    await fetchInvoices(
      () => {
        setLoading(false);
      },
      {
        from: format(from, URL_DATE_FORMAT),
        to: format(to, URL_DATE_FORMAT),
      }
    );
  };

  const fields = [
    [
      {
        label: 'From',
        type: 'date',
        name: 'from',
        value: from ? new Date(from) : null,
        maxDate: new Date(to),
        size: '6',
        disabled: loading,
        required: true,
        slotProps: {
          actionBar: { actions: ['clear', 'today'] },
        },
      },
      {
        label: 'To',
        type: 'date',
        name: 'to',
        minDate: from,
        value: to ? new Date(to) : null,
        maxDate: new Date(),
        size: '6',
        disabled: loading,
        required: true,
        slotProps: {
          actionBar: { actions: ['clear', 'today'] },
        },
      },
    ],
  ];

  const handleChange = ({ field, payload }) => {
    dispatch({
      field,
      payload,
    });
  };

  return (
    <Form
      data={state}
      fields={fields}
      loadingAction={setLoading}
      changeAction={handleChange}
      submitAction={handleDownload}
    >
      {loading && (
        <Loading autoHeight>
          <p>Please wait — This can take several minutes</p>
        </Loading>
      )}
      <Button
        type="submit"
        sx={{ float: 'right', mt: 2 }}
        variant="contained"
        disabled={loading}
        data-testid="download-csv-button"
      >
        Download CSV
      </Button>
    </Form>
  );
};

export default Invoices;
