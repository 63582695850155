import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import './Radio.scss';

const RadioButtons = ({
  correct,
  disabled,
  label,
  name,
  options,
  onChange,
  value,
}) => (
  <FormControl>
    {label && <FormLabel id={name}>{label}</FormLabel>}
    <RadioGroup
      row
      data-testid={name}
      aria-labelledby={name}
      name={name}
      sx={{ display: 'flex', width: '100%' }}
      onChange={onChange}
      value={typeof value === 'boolean' ? value.toString() : value}
    >
      {options.map(({ label: optionLabel, value: optionValue }) => (
        <FormControlLabel
          key={`${name}-${optionValue}`}
          data-testid={`${name}-${optionValue}`}
          value={optionValue}
          control={<Radio size="large" />}
          sx={{ mr: 5 }}
          label={
            <>
              {optionLabel} {optionValue === correct && <sup>†</sup>}
            </>
          }
        />
      ))}
    </RadioGroup>
  </FormControl>
);

RadioButtons.propTypes = {
  correct: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

RadioButtons.defaultProps = {
  label: null,
  correct: null,
  options: [],
  disabled: false,
  value: null,
};

export default RadioButtons;
