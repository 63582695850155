import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Link } from '@mui/material';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Loading from '../../Loading';
import NoRowsOverlay from '../../NoRowsOverlay';

import { fetchLeads } from '../../../api/leads';

import {
  FORD_ADMIN_ROLE,
  DATE_TIME_FORMAT,
  PATHS,
  VEHICLE_TYPE_LABELS,
} from '../../../constants';

const CustomToolbar = ({ setDate, date, setDateFilteredLeads, leads }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <GridToolbarContainer sx={{ py: 2, px: 2, justifyContent: 'flex-end' }}>
      <DatePicker
        sx={{ mt: '-4px', mr: 1 }}
        format="d MMM yyyy"
        value={date}
        onChange={(value) => {
          setDate(value);
          setDateFilteredLeads(
            leads.filter(({ createdAt }) => value < new Date(createdAt))
          );
        }}
        slotProps={{
          field: { shouldRespectLeadingZeros: true },
          textField: { placeholder: 'Signed up since' },
        }}
      />
      <GridToolbarQuickFilter variant="outlined" debounceMs={50} />
    </GridToolbarContainer>
  </LocalizationProvider>
);

CustomToolbar.propTypes = {
  setDate: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  setDateFilteredLeads: PropTypes.func.isRequired,
  leads: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

CustomToolbar.defaultProps = {
  date: null,
};

const LeadList = () => {
  const [leads, setLeads] = useState([]);
  const [dateFilteredLeads, setDateFilteredLeads] = useState(leads);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(null);

  const CustomerReferenceNumberLink = useCallback(
    ({ row }) =>
      loading || !window.userRoles.includes(FORD_ADMIN_ROLE) ? (
        row.customerReferenceNumber
      ) : (
        <Link
          data-testid="edit-link"
          href={`${PATHS.LEADS}/${PATHS.LEADS_EDIT}/${row.id}`}
        >
          {row.customerReferenceNumber}
        </Link>
      ),
    [loading]
  );

  const getLeads = useCallback(async () => {
    setLoading(true);
    await fetchLeads(({ leads: newLeads }) => {
      setLeads(newLeads);
      setLoading(false);
    });
  }, []);

  const AddressText = useCallback(
    ({ row: { city, houseNumberAndStreet, postcode } }) =>
      `${houseNumberAndStreet ? `${houseNumberAndStreet}\n` : ''}
      ${city ? `${city}\n` : ''}
      ${postcode || ''}`,
    []
  );

  const startColumns = [
    {
      headerName: 'Customer Reference',
      field: 'customerReferenceNumber',
      renderCell: CustomerReferenceNumberLink,
      flex: 1,
    },
    {
      headerName: 'Name',
      field: 'firstName',
      flex: 1,
      valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`,
    },
    {
      headerName: 'Email Address',
      field: 'email',
      flex: 1,
    },
    {
      headerName: 'Phone Number',
      field: 'phoneNumber',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Lead Type',
      flex: 0.7,
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      valueGetter: ({ row }) => row.company?.name,
      flex: 1,
    },
  ];

  const fclgColumns = [
    ...startColumns,
    {
      field: 'postcode',
      headerName: 'Address',
      valueGetter: AddressText,
      flex: 1,
    },
    {
      field: 'dateOfBirth',
      headerName: 'Date of Birth',
      flex: 1,
    },
    {
      field: 'registrationNumber',
      headerName: 'Reg No',
      valueGetter: ({ row }) => {
        const reservations = row.customer?.reservations;
        if (!reservations) return null;

        const lastReservation = reservations.length - 1;
        return reservations[lastReservation]?.vehicle?.registrationNumber;
      },
      flex: 1,
    },
    {
      field: 'vin',
      headerName: 'VIN',
      valueGetter: ({ row }) => {
        const reservations = row.customer?.reservations;
        if (!reservations) return null;

        const lastReservation = reservations.length - 1;
        return reservations[lastReservation]?.vehicle?.vin;
      },
      flex: 1,
    },
  ];

  const adminColumns = [
    ...startColumns,
    {
      field: 'marketingConsent',
      headerName: 'Marketing Consent',
      valueGetter: ({ row: { marketingConsent } }) => {
        if (!window.userRoles.includes(FORD_ADMIN_ROLE)) return '-';
        return marketingConsent ? '✅ ‌ Yes' : '❌ ‌ No';
      },
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      renderCell: ({ row }) => {
        if (!window.userRoles.includes(FORD_ADMIN_ROLE)) return '-';
        return row.createdAt
          ? format(new Date(row.createdAt), DATE_TIME_FORMAT)
          : '-';
      },
      flex: 1,
    },
    {
      field: 'vehiclePreference',
      headerName: 'Vehicle Preference',
      valueGetter: ({ row: { vehiclePreference } }) =>
        VEHICLE_TYPE_LABELS[vehiclePreference],
      flex: 1.2,
    },
  ];

  useEffect(() => {
    getLeads();
  }, [getLeads]);

  if (loading) return <Loading />;

  return (
    <DataGrid
      sx={{
        my: 2,
        width: '100%',
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
          py: '22px',
        },
        '&.MuiDataGrid-root .MuiDataGrid-overlayWrapper': {
          height: '3rem !important',
        },
      }}
      components={{ NoRowsOverlay, toolbar: CustomToolbar }}
      slotProps={{
        toolbar: { setDate, date, setDateFilteredLeads, leads },
        noRowsOverlay: { type: 'leads' },
      }}
      disableRowSelectionOnClick
      disableSelectionOnClick
      density="compact"
      getRowHeight={() => 'auto'}
      rows={date ? dateFilteredLeads : leads}
      columns={
        window.userRoles.includes(FORD_ADMIN_ROLE) ? adminColumns : fclgColumns
      }
      initialState={{
        sorting: {
          sortModel: [{ field: 'createdAt', sort: 'desc' }],
        },
      }}
      onClipboardCopy={(string) => {
        const count = string.split(/\r\n|\r|\n/).length;

        window.setBanner({
          message: `${count} lead${
            count.length !== 1 && 's'
          } copied to clipboard`,
          type: 'success',
        });
      }}
    />
  );
};

export default LeadList;
