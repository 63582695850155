import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { ERROR_MESSAGES } from '../constants';
import defaultFailure from './defaultFailure';

const { REACT_APP_API_URL } = process.env;

export const acquireAccessToken = async () => {
  let token = null;

  // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const activeAccount = window.msalInstance?.getActiveAccount();
  const accounts = window.msalInstance?.getAllAccounts();

  if (!activeAccount && accounts?.length === 0) {
    // eslint-disable-next-line
    return false;
  }

  const request = {
    scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/.default`],
    account: activeAccount || accounts?.[0],
  };

  try {
    const authResult = await window.msalInstance?.acquireTokenSilent(request);
    token = authResult?.accessToken;
  } catch (error) {
    const { errorMessage } = error;
    if (error instanceof InteractionRequiredAuthError) {
      window.msalInstance?.acquireTokenRedirect(request);
    }
    // eslint-disable-next-line
    console.log(errorMessage || error);
  }

  return token;
};

export const api = async (endpoint, method, callback, options = {}) => {
  const accessToken = await acquireAccessToken();

  const { data = null, failure = () => {} } = options;

  const bodyData =
    (method === 'POST' ||
      method === 'UPDATE' ||
      method === 'PUT' ||
      method === 'PATCH') &&
    data
      ? JSON.stringify(data)
      : null;

  if (accessToken && REACT_APP_API_URL && endpoint) {
    await fetch(`${REACT_APP_API_URL}${endpoint}`, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Bearer ${accessToken}`,
      },
      body: bodyData,
    })
      .then((response) => {
        if (!response?.ok) {
          throw Error(response.status);
        }
        return response;
      })
      .then(async (response) => {
        const responseText = await response.text();
        return responseText === '' ? {} : JSON.parse(responseText);
      })
      .then((response) => callback && callback(response))
      .catch((error) => {
        const failureMessage = ERROR_MESSAGES[error.message] || '';

        failure();
        defaultFailure(
          `${error.name} at ${endpoint}: ${error.message} ${failureMessage}`
        );
      });
  }
};

export const showSuccess = (navigate, url, message) => {
  navigate(url, {
    state: {
      type: 'success',
      message: message || 'Success',
    },
  });
};

export const showWarning = (navigate, url, warning) => {
  navigate(url, {
    state: {
      type: 'warning',
      message: warning || 'Warning',
    },
  });
};

export const showError = (navigate, url, error) => {
  navigate(url, {
    state: {
      type: 'error',
      message: error || 'Error: Something went wrong. Please try again',
    },
  });
};
