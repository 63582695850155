import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import './Loading.scss';

const Loading = ({ children, autoHeight }) => (
  <div
    data-testid="loading"
    className={`loading ${autoHeight ? 'loading--autoheight' : ''}`}
  >
    <CircularProgress />
    <div className="loading__children">{children}</div>
  </div>
);

Loading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  autoHeight: PropTypes.bool,
};

Loading.defaultProps = {
  children: null,
  autoHeight: false,
};

export default Loading;
