import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import CheckboxWithLabel from '../CheckboxWithLabel';
import RadioButtons from '../RadioButtons';
import { REGEX } from '../../constants';

import './Form.scss';

const fieldComponents = {
  checkbox: CheckboxWithLabel,
  date: DatePicker,
  email: TextField,
  radio: RadioButtons,
  tel: TextField,
  text: TextField,
  number: TextField,
  time: TextField,
  select: TextField,
};

const fieldPatterns = {
  email: REGEX.EMAIL,
  text: REGEX.NOHTML,
  time: REGEX.TIME,
  tel: REGEX.PHONE_NUMBER,
};

const Form = ({
  children,
  data = {},
  fields = [],
  initialErrors,
  loadingAction,
  changeAction,
  style,
  submitAction,
}) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors(initialErrors);
  }, [initialErrors]);

  const validateFields = () => {
    const fieldErrors = [];
    setErrors([]);

    fields.forEach((row) =>
      row.forEach(
        ({
          alternatives = [],
          defaultValue = null,
          hidden,
          label,
          minLength,
          name,
          type,
          pattern = null,
          required,
        }) => {
          loadingAction(false);

          if (hidden) return true;

          if (required || minLength > 0) {
            if (
              !data[name] ||
              (Array.isArray(data[name]) && !data[name].length)
            ) {
              if (alternatives.length) {
                const validAlternatives = alternatives.filter(
                  (alternative) =>
                    !!data[alternative] &&
                    ((Array.isArray(data[alternative]) &&
                      data[alternative].length) ||
                      !Array.isArray(data[alternative]))
                );
                if (!validAlternatives.length) {
                  fieldErrors[name] = `${label} is required`;
                  return null;
                }
              } else if (defaultValue !== null) {
                const newData = {};
                newData[name] = defaultValue;
                changeAction({
                  field: name,
                  payload: defaultValue,
                });
                return newData[name];
              } else {
                fieldErrors[name] = `${label} is required`;
                return null;
              }
            }

            if (
              !!data[name] &&
              typeof data[name] === 'object' &&
              !(data[name] instanceof Date) &&
              !Object.keys(data[name]).length
            ) {
              fieldErrors[name] = `${label} is required`;
              return null;
            }
          }

          if (
            ((!required && data[name] && data[name].length) || required) &&
            ((!!pattern && !pattern.test(data[name])) ||
              (!!fieldPatterns[type] && !fieldPatterns[type].test(data[name])))
          ) {
            fieldErrors[name] = `${label} is invalid`;
            return null;
          }

          return true;
        }
      )
    );

    if (Object.keys(fieldErrors).length) {
      setErrors([]);
      setErrors(fieldErrors);
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateFields() && submitAction();
  };

  return (
    <>
      {!!errors.length && (
        <ul className="form__errors">
          {errors.map(({ field, issue }) => (
            <li key={field}>
              {field} is {issue}
            </li>
          ))}
        </ul>
      )}
      <form onSubmit={handleSubmit} noValidate data-testid="form" style={style}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {fields?.map((row, i) => (
            <Grid
              container
              key={`row-${row.map(({ name }) => name).join('-')}`}
              spacing={2}
              sx={{ my: 1 }}
            >
              {row.map((field) => {
                const {
                  hidden,
                  label,
                  name,
                  onChange,
                  options,
                  pattern = fieldPatterns[field.type] || null,
                  size,
                  slotProps,
                  type,
                } = field;

                // Prevent a bad field type from causing a problem
                if (!fieldComponents[type]) return null;

                const Component = fieldComponents[type];
                let fieldProps = {
                  ...field,
                  onChange:
                    onChange ||
                    (({ target }) => {
                      changeAction({
                        field: name,
                        payload: target?.value,
                      });
                    }),
                };

                let value = null;
                let defaultValue = null;

                if (field.defaultValue) {
                  if (type === 'date') {
                    defaultValue = new Date(defaultValue);
                  } else {
                    defaultValue = field.defaultValue;
                  }
                  fieldProps.defaultValue = defaultValue;
                } else {
                  if (type === 'date') {
                    if (data[name]) value = new Date(data[name]);
                  } else {
                    if (fieldProps.multiple) {
                      value = [];
                    } else {
                      value = '';
                    }
                    if (data[name]) value = data[name];
                  }
                  fieldProps.value = value;
                }

                fieldProps.error =
                  !!errors[name] ||
                  (pattern && !pattern?.test(defaultValue || value)) ||
                  null;

                if (errors[name]) fieldProps.helperText = errors[name];

                if (pattern && !pattern?.test(defaultValue || value))
                  fieldProps.helperText = `${label} is invalid`;

                if (
                  type === 'text' ||
                  type === 'email' ||
                  type === 'tel' ||
                  type === 'number'
                ) {
                  fieldProps = {
                    fullWidth: true,
                    ...fieldProps,
                    helperText: errors[name] || fieldProps.helperText || null,
                    inputProps: {
                      maxLength: fieldProps.maxLength,
                    },
                  };
                }

                if (type === 'checkbox') {
                  fieldProps = {
                    ...fieldProps,
                    size: 'large',
                    value: value === true || value === 'true',
                    onChange:
                      onChange ||
                      (({ target }) => {
                        changeAction({
                          field: name,
                          payload: target.checked,
                        });
                      }),
                  };

                  delete fieldProps.helperText;
                }

                if (type === 'date') {
                  fieldProps = {
                    fullWidth: true,
                    format: 'd MMM yyyy',
                    ...fieldProps,
                    slotProps: {
                      ...slotProps,
                      field: { shouldRespectLeadingZeros: true },
                      textField: {
                        fullWidth: true,
                        placeholder: fieldProps.label,
                      },
                    },
                    onChange:
                      onChange ||
                      ((dateValue) => {
                        changeAction({
                          field: name,
                          payload: dateValue,
                        });
                      }),
                  };
                }

                if (type === 'time') {
                  fieldProps = {
                    fullWidth: true,
                    className: 'fds-text-field--time',
                    pattern: pattern || REGEX.TIMERAW,
                    ...fieldProps,
                  };
                }

                if (type === 'select') {
                  fieldProps = {
                    ...fieldProps,
                    value:
                      options.length < 2 && options[0]?.value === ''
                        ? ''
                        : value,
                    select: true,
                    fullWidth: true,
                    SelectProps: {
                      multiple: fieldProps.multiple,
                    },
                    children: options.map(
                      ({
                        disabled,
                        label: optionLabel,
                        value: optionValue,
                      }) => (
                        <MenuItem
                          value={optionValue}
                          key={`${name}-${optionValue}`}
                          disabled={disabled}
                        >
                          {optionLabel}
                        </MenuItem>
                      )
                    ),
                  };
                }

                return !!type && type !== 'hidden' ? (
                  <Grid item key={`column-${name}`} xs={parseInt(size, 10)}>
                    {!hidden && <Component {...fieldProps} />}
                  </Grid>
                ) : (
                  <Component {...fieldProps} />
                );
              })}
            </Grid>
          ))}
          {children}
        </LocalizationProvider>
      </form>
    </>
  );
};

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hidden: PropTypes.bool,
  data: PropTypes.shape(),
  initialErrors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      issue: PropTypes.string.isRequired,
    })
  ),
  fields: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
  ),
  loadingAction: PropTypes.func,
  changeAction: PropTypes.func.isRequired,
  style: PropTypes.shape(),
  submitAction: PropTypes.func.isRequired,
};

Form.defaultProps = {
  children: null,
  data: {},
  fields: null,
  hidden: false,
  initialErrors: [],
  loadingAction: () => {},
  style: {},
};

export default Form;
