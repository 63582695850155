import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Menu, MenuItem, Stack } from '@mui/material';

import { PATHS } from '../../constants';

const UserButton = ({ instance }) => {
  const { accounts } = useMsal();
  const userName = accounts[0]?.name;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  if (!userName) return <>👤</>;

  const initials = userName
    ?.split(', ')
    .map((w) => w[0])
    .reverse()
    .join('');

  return (
    <>
      <Button
        variant="contained"
        to={PATHS.USER}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        sx={{ ml: 2, px: 1, minWidth: '2.3rem', borderRadius: 100 }}
      >
        {initials}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            instance.logoutRedirect({
              postLogoutRedirectUri: '/',
            });
          }}
        >
          Log Out
        </MenuItem>
      </Menu>
    </>
  );
};

UserButton.propTypes = {
  instance: PropTypes.shape().isRequired,
};

const NavItem = ({ title, url, children, instance, isAuthenticated, menu }) =>
  menu &&
  (url?.startsWith('http') ? (
    <Button component={Link} to={url} sx={{ px: 2 }}>
      {title}
    </Button>
  ) : (
    <Button sx={{ px: 2 }} component={NavLink} to={url}>
      {title}
    </Button>
  ));

NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  instance: PropTypes.shape().isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  menu: PropTypes.bool,
};

NavItem.defaultProps = {
  children: null,
  menu: true,
};

const Nav = ({ testId, navItems, userRoles }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const showInNav = ({ menu, roles = [], secure = true }) =>
    (isAuthenticated || !secure) &&
    (roles.some((role) => userRoles.includes(role)) || roles?.length === 0) &&
    !menu === true;

  return (
    navItems && (
      <nav className="nav" data-testid={testId}>
        <Stack
          direction="row"
          alignItems="center"
          component="ul"
          sx={{ listStyle: 'none', paddingLeft: '0', margin: '0' }}
        >
          {navItems.filter(showInNav).map((item) => (
            <li key={item.title}>
              <NavItem isAuthenticated {...item} instance={instance} />
            </li>
          ))}
          <li>
            <UserButton instance={instance} />
          </li>
        </Stack>
      </nav>
    )
  );
};

Nav.propTypes = {
  testId: PropTypes.string,
  navItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  userRoles: PropTypes.arrayOf(PropTypes.string),
};

Nav.defaultProps = {
  testId: '',
  navItems: null,
  userRoles: [],
};

export default Nav;
