import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { Button } from '@mui/material';

import PageContent from '../../components/PageContent';

import { loginRequest } from '../../authConfig';

const SignIn = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleSignInClick = async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      try {
        await instance.loginRedirect(loginRequest);
      } catch ({ errorMessage }) {
        window.setBanner({
          type: 'error',
          message: errorMessage?.split(' Detail:')[0],
        });
      }
    }
  };

  return (
    <PageContent title="Sign In" testId="sign-in-page">
      <p style={{ clear: 'both', textAlign: 'center' }}>
        {window.navigator.onLine ? (
          <>
            You need to sign in to use Ford Drive Admin
            <br />
            <br />
            <Button
              variant="contained"
              onClick={handleSignInClick}
              data-testid="signInButton"
            >
              Click here to sign in
            </Button>
          </>
        ) : (
          <>
            You need to be online to use Ford Drive Admin
            <br />
            <br />
            Please connect to the internet
          </>
        )}
      </p>
    </PageContent>
  );
};

export default SignIn;
