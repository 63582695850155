import { saveAs } from 'file-saver';
import { acquireAccessToken } from '..';
import { ENDPOINTS } from '../../constants';

const { REACT_APP_API_URL } = process.env;

// eslint-disable-next-line import/prefer-default-export
export const fetchInvoices = async (callback, { from, to }) => {
  const accessToken = await acquireAccessToken();

  await fetch(
    `${REACT_APP_API_URL}${ENDPOINTS.INVOICES}?from=${from}&to=${to}`,
    {
      method: 'GET',
      headers: {
        Accept: '*',
        'Content-Type':
          '.csv, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
    .then((res) => res.blob())
    .then((blob) => saveAs(blob, `invoices-${from}-${to}.csv`))
    .then(() => callback())
    // eslint-disable-next-line no-alert
    .catch((err) => alert(err));
};
