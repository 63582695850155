import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { PATHS } from '../../../constants';

import NotFound from '../../NotFound';

import LeadForm from '../../../components/Leads/Form';
import Loading from '../../../components/Loading';
import PageContent from '../../../components/PageContent';

import { fetchLead } from '../../../api/leads';

const LeadsFormPage = () => {
  const { leadId } = useParams();

  const [lead, setLead] = useState({});
  const [loading, setLoading] = useState(!!leadId);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const getLeads = async () => {
      setLoading(true);
      await fetchLead(
        (response) => {
          if (response) {
            setLead(response);
          } else {
            setNotFound(true);
          }
          setLoading(false);
        },
        {
          id: leadId,
        }
      );
    };

    if (leadId) getLeads();
  }, [leadId]);

  if (loading) return <Loading />;
  if (notFound) return <NotFound />;

  return (
    <PageContent
      preTitle={leadId ? 'Edit Lead' : ''}
      title={leadId ? `${lead.firstName} ${lead.lastName}` : 'New Lead'}
      testId="leads-form-page"
    >
      <Button variant="text" href={PATHS.LEADS} sx={{ float: 'right' }}>
        Back to Leads
      </Button>
      <LeadForm lead={lead} setLead={setLead} />
    </PageContent>
  );
};

LeadsFormPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      leadId: PropTypes.string,
    }),
  }),
};

LeadsFormPage.defaultProps = {
  match: {
    params: {
      leadId: null,
    },
  },
};

export default LeadsFormPage;
