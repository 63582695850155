import { useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import {
  DATE_TIME_FORMAT,
  REGEX,
  PATHS,
  VEHICLE_TYPE_LABELS,
  ACTIVE_VEHICLE_TYPES,
} from '../../../constants';

import Form from '../../Form';
import Loading from '../../Loading';

import { showSuccess } from '../../../api';
import { updateLead, createLead, deleteLead } from '../../../api/leads';

const formReducer = (state, { field, payload, subField }) => {
  if (subField) {
    const reduced = {
      ...state,
    };

    if (reduced[field]) {
      reduced[field][subField] = payload;
    } else {
      reduced[field] = {};
      reduced[field][subField] = payload;
    }
    return reduced;
  }

  return {
    ...state,
    [field]: payload,
  };
};

function uniqueUnion(array, element) {
  if (element === undefined) return array;

  return [...new Set([...array, element])];
}

const LeadForm = ({ lead, setLead }) => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const buttonRef = useRef(null);
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(formReducer, lead);

  const {
    customerReferenceNumber,
    firstName = '',
    id,
    lastName = '',
    marketingConsent,
    type = 'B2B',
    vehiclePreference,
  } = state;

  useEffect(() => {
    state.company && setCompany(state.company);
  }, [state.company, setCompany]);

  const handleDelete = async (e) => {
    e.preventDefault();

    setLoading(true);

    await deleteLead(
      () => {
        setLoading(false);
        showSuccess(
          navigate,
          PATHS.LEADS,
          `Lead ‘${firstName} ${lastName}’ deleted`
        );
      },
      {
        id,
        failure: (error) => {
          setLoading(false);
          window.setBanner({
            message: error,
            type: 'error',
          });
        },
      }
    );
  };

  const handleSubmitForm = async () => {
    setLoading(true);

    const leadData = {
      ...state,
    };

    if (!company?.name) {
      setLoading(false);
      window.setBanner({
        message: 'Company details are required',
        type: 'error',
      });
      return false;
    }

    leadData.company = company;

    delete leadData.id;
    delete leadData.createdAt;
    delete leadData.customerReferenceNumber;
    delete leadData.emailHistories;
    delete leadData.unconsentReasons;

    leadData.marketingConsent = !!marketingConsent;

    if (id) {
      await updateLead(
        () => {
          setLoading(false);
          showSuccess(
            navigate,
            PATHS.LEADS,
            `Lead ‘${firstName} ${lastName}’ updated`
          );
        },
        {
          id,
          data: leadData,
          failure: (error) => {
            setLoading(false);
            window.setBanner({
              message: error,
              type: 'error',
            });
          },
        }
      );
    } else {
      await createLead(
        () => {
          setLoading(false);
          showSuccess(
            navigate,
            PATHS.LEADS,
            `New lead ‘${firstName} ${lastName}’ created`
          );
        },
        {
          data: leadData,
          failure: (error) => {
            setLoading(false);
            window.setBanner({
              message: error,
              type: 'error',
            });
          },
        }
      );
    }

    return true;
  };

  const fields = [
    [
      {
        label: 'First name',
        minLength: 2,
        name: 'firstName',
        pattern: REGEX.NAME,
        required: true,
        type: 'text',
        size: '3',
      },
      {
        label: 'Middle name',
        name: 'middleName',
        pattern: REGEX.NAME,
        type: 'text',
        size: '3',
      },
      {
        label: 'Last name',
        minLength: 2,
        name: 'lastName',
        pattern: REGEX.NAME,
        required: true,
        type: 'text',
        size: '3',
      },
      {
        label: 'Marketing consent',
        name: 'marketingConsent',
        type: 'checkbox',
        size: '3',
      },
    ],
    [
      {
        label: 'Phone number',
        minLength: 2,
        maxLength: 13,
        name: 'phoneNumber',
        required: true,
        type: 'tel',
        size: '4',
      },
      {
        label: 'Email address',
        minLength: 5,
        name: 'email',
        required: true,
        type: 'email',
        size: '4',
      },
    ],
    [
      {
        label: 'Lead Type',
        name: 'type',
        type: 'select',
        required: true,
        disabled: !!id,
        options: [
          {
            label: 'Not Selected',
            value: '',
            disabled: true,
          },
          {
            label: 'B2B',
            value: 'B2B',
          },
        ],
        size: '6',
      },
    ],
  ];

  const options = uniqueUnion(ACTIVE_VEHICLE_TYPES, vehiclePreference)
    .map((vehicle) => ({
      value: vehicle,
      label: VEHICLE_TYPE_LABELS[vehicle],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  fields[2].push({
    label: 'Vehicle preference',
    name: 'vehiclePreference',
    type: 'select',
    disabled: !type,
    required: true,
    options,
    size: '6',
  });

  const companyFields = [
    [
      {
        label: 'Company name',
        name: 'name',
        type: 'text',
        size: '6',
        required: true,
        pattern: REGEX.COMPANY_NAME,
      },
      {
        label: 'Company registration number',
        name: 'number',
        type: 'text',
        size: '6',
        maxLength: 10,
        pattern: REGEX.COMPANY_NUMBER,
      },
    ],
  ];

  const handleChange = ({ field, payload, subField }) => {
    dispatch({
      field,
      payload,
      subField,
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      {id && (
        <List sx={{ py: 2, clear: 'both' }}>
          <ListItem disablePadding>
            <ListItemText
              primary={`Customer reference number: ${customerReferenceNumber}`}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={`Date added: ${format(
                new Date(state.createdAt),
                DATE_TIME_FORMAT
              )}`}
            />
          </ListItem>
        </List>
      )}
      <Form
        data={state}
        fields={fields}
        changeAction={handleChange}
        submitAction={handleSubmitForm}
      >
        <Button hidden type="submit" ref={buttonRef} />
      </Form>
      <Form
        data={company}
        fields={companyFields}
        changeAction={({ field, payload }) => {
          setCompany({
            ...company,
            [field]: payload,
          });
        }}
        style={{ marginTop: '-2rem' }}
        submitAction={() => {
          buttonRef?.current?.click();
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 6,
            mb: 6,
          }}
        >
          <Button
            disabled={!id}
            onClick={() => setDeleteDialogOpen(true)}
            color="error"
            variant="outlined"
            data-testid="delete-button"
          >
            Delete lead
            <span className="hidden">
              {' '}
              {firstName} {lastName}
            </span>
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            onClick={() => buttonRef?.current?.click()}
          >
            Save Lead
          </LoadingButton>
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
          >
            <DialogTitle id="delete-dialog-title">
              Confirm Delete Lead
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                Are you sure you want to delete the lead {firstName} {lastName}?
              </DialogContentText>
              <DialogActions>
                <Button
                  onClick={() => setDeleteDialogOpen(false)}
                  variant="text"
                >
                  Cancel
                </Button>
                <Button
                  onClick={(e) => handleDelete(e)}
                  color="error"
                  variant="contained"
                  data-testid="confirm-delete-button"
                >
                  Delete lead
                  <span className="hidden">
                    {' '}
                    {firstName} {lastName}
                  </span>
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        </Grid>
      </Form>
    </>
  );
};

LeadForm.propTypes = {
  lead: PropTypes.shape({
    affordability: PropTypes.shape(),
    campaign: PropTypes.string,
    city: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
    companyName: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    customer: PropTypes.shape(),
    customerReferenceNumber: PropTypes.string,
    dateOfBirth: PropTypes.string,
    declaration: PropTypes.shape(),
    editedAt: PropTypes.string,
    editedBy: PropTypes.string,
    eligibility: PropTypes.shape(),
    email: PropTypes.string,
    firstName: PropTypes.string,
    houseNumberAndStreet: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    marketingConsent: PropTypes.bool,
    middleName: PropTypes.string,
    phoneNumber: PropTypes.string,
    pcoLicense: PropTypes.shape(),
    postcode: PropTypes.string,
    rideHail: PropTypes.shape(),
    status: PropTypes.string,
    type: PropTypes.string,
    vehicleCollected: PropTypes.bool,
    vehiclePreference: PropTypes.string,
    vehicleRegistrationNumber: PropTypes.string,
  }),
  setLead: PropTypes.func.isRequired,
};

LeadForm.defaultProps = {
  lead: null,
};

export default LeadForm;
