import React, { useEffect, useState } from 'react';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { Alert, Snackbar } from '@mui/material';

import PropTypes from 'prop-types';

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import ReportingPage from './pages/Reporting';

import LeadFormPage from './pages/Leads/Form';
import LeadListPage from './pages/Leads/List';

import NotFound from './pages/NotFound';
import SignIn from './pages/SignIn';

import Footer from './components/Footer';
import Loading from './components/Loading';
import Header from './components/Header';
import PageContent from './components/PageContent';

import { PATHS, FORD_ADMIN_ROLE, PAYMENTS_ADMIN_ROLE } from './constants';

import './styles/base.scss';

// eslint-disable-next-line
window.setBanner = console.log;

const siteMap = [
  {
    title: 'Reporting',
    url: PATHS.REPORTING,
    exact: false,
    component: ReportingPage,
    roles: [PAYMENTS_ADMIN_ROLE],
  },
  {
    title: 'Leads',
    url: PATHS.LEADS,
    exact: false,
    component: LeadListPage,
    roles: [FORD_ADMIN_ROLE],
    children: [
      {
        menu: false,
        title: 'Add Lead',
        url: PATHS.LEADS_NEW,
        component: LeadFormPage,
        roles: [FORD_ADMIN_ROLE],
      },
      {
        menu: false,
        title: 'Edit Lead',
        url: `${PATHS.LEADS_EDIT}/:leadId`,
        exact: false,
        component: LeadFormPage,
        roles: [FORD_ADMIN_ROLE],
      },
    ],
  },
];

const Forbidden = ({ title }) => (
  <PageContent>
    <p style={{ width: '100%', textAlign: 'center' }}>
      You do not have permission to access the {title} page
    </p>
  </PageContent>
);

Forbidden.propTypes = {
  title: PropTypes.string.isRequired,
};

const Page = ({
  children,
  component: Component,
  hideNav,
  slug,
  secure = true,
  roles = [],
  title,
  ...rest
}) => {
  const [banner, setBanner] = useState(null);

  const { state: locationBanner } = useLocation();

  useEffect(() => {
    // Allow this banner instance to be accessed globally
    window.setBanner = setBanner;
  }, []);

  useEffect(() => {
    // If the page is passed a banner in the location state, show it
    setBanner(locationBanner);
  }, [locationBanner]);

  useEffect(() => {
    window.banner = banner;
  }, [banner]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setBanner(null);
  };

  return (
    <>
      {!!banner && (
        <Snackbar
          open={!!banner}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
        >
          <Alert onClose={handleClose} severity={banner?.type || 'info'}>
            {banner?.message || ''}
          </Alert>
        </Snackbar>
      )}
      <Header
        navItems={hideNav ? null : siteMap}
        userRoles={window.userRoles}
      />
      {secure && !window.userRoles.length && <Loading />}
      {(!secure ||
        (secure && roles.some((role) => window.userRoles.includes(role)))) && (
        <>
          {children}
          <Component {...rest} />
        </>
      )}
      {secure &&
        !!window.userRoles &&
        !roles.some((role) => window.userRoles.includes(role)) &&
        (title === 'Not Found' ? <NotFound /> : <Forbidden title={title} />)}
      <Footer navItems={siteMap} />
    </>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  component: PropTypes.func.isRequired,
  hideNav: PropTypes.bool,
  location: PropTypes.shape(),
  roles: PropTypes.arrayOf(PropTypes.string),
  slug: PropTypes.string.isRequired,
  secure: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

Page.defaultProps = {
  children: null,
  hideNav: false,
  location: {},
  roles: [],
  secure: true,
};

const HomePage = ({ userRoles }) => {
  let url = '/leads';
  if (userRoles?.includes(PAYMENTS_ADMIN_ROLE)) url = '/reporting';
  return <Navigate to={url} replace />;
};

HomePage.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string),
};

HomePage.defaultProps = {
  userRoles: [],
};

const MainContent = () => {
  const isAuthenticated = useIsAuthenticated();
  const [userRoles, setUserRoles] = useState([]);
  const { accounts } = useMsal();

  useEffect(() => {
    window.isAuthenticated = isAuthenticated;
    if (isAuthenticated && !!accounts[0]?.idTokenClaims?.roles.length) {
      window.userRoles = accounts[0]?.idTokenClaims?.roles;
      setUserRoles(accounts[0]?.idTokenClaims?.roles);
    } else {
      window.userRoles = [];
      setUserRoles([]);
    }
  }, [accounts, isAuthenticated]);

  return (
    <>
      <AuthenticatedTemplate>
        <Router onUpdate={() => document.getElementById('root').focus()}>
          <Routes>
            <Route
              exact
              path={PATHS.HOME}
              element={<HomePage userRoles={userRoles} />}
            />
            {siteMap.map(
              ({
                title,
                url,
                exact = true,
                children = [],
                component = () => {},
                secure = true,
                roles,
              }) => {
                const slug = title.toLowerCase().replaceAll(' ', '-');

                return (
                  <React.Fragment key={url}>
                    <Route
                      exact={exact}
                      path={url}
                      element={
                        <Page
                          secure={secure}
                          component={component}
                          slug={slug}
                          roles={roles}
                          title={title}
                        />
                      }
                    />
                    {children?.map(
                      ({
                        url: childUrl,
                        exact: childExact,
                        component: childComponent,
                        roles: childRoles,
                      }) => {
                        const fullUrl = childUrl?.startsWith('/')
                          ? childUrl
                          : `${url}/${childUrl}`;

                        return (
                          childComponent && (
                            <Route
                              exact={childExact}
                              path={fullUrl}
                              key={fullUrl}
                              element={
                                <Page
                                  component={childComponent}
                                  secure={secure}
                                  slug={slug}
                                  roles={childRoles}
                                  title={title}
                                />
                              }
                            />
                          )
                        );
                      }
                    )}
                  </React.Fragment>
                );
              }
            )}
            <Route
              key="404"
              path="*"
              element={
                <Page component={NotFound} title="Not Found" slug="not-found" />
              }
            />
          </Routes>
        </Router>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Router onUpdate={() => document.getElementById('root').focus()}>
          <Page
            component={SignIn}
            secure={false}
            slug="sign-in"
            title="Sign In"
            hideNav
          />
        </Router>
      </UnauthenticatedTemplate>
    </>
  );
};

const App = () => (
  <MsalProvider instance={window.msalInstance}>
    <MainContent />
  </MsalProvider>
);

export default App;
