import { ENDPOINTS } from '../../constants';
import { api } from '..';

export const fetchLead = (callback, options) =>
  api(
    `${ENDPOINTS.LEAD}/${options.id}`,
    'GET',
    ({ lead }) => callback(lead),
    options
  );
export const fetchLeads = (callback, options) =>
  api(ENDPOINTS.LEADS, 'GET', callback, options);

export const createLead = (callback, options) =>
  api(ENDPOINTS.LEADS_NEW, 'POST', callback, {
    ...options,
    data: options.data,
  });

export const updateLead = (callback, options) =>
  api(`${ENDPOINTS.LEADS_EDIT}/${options.id}`, 'PUT', () => callback(options), {
    ...options,
    data: options.data,
  });

export const deleteLead = (callback, options) =>
  api(
    `${ENDPOINTS.LEADS_DELETE}/${options.id}`,
    'DELETE',
    () => callback(options),
    options
  );
