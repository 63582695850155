import PropTypes from 'prop-types';

import { Box, Divider, Link, Toolbar, Typography } from '@mui/material';
import Nav from '../Nav';

import { APP_NAME } from '../../constants';

const Header = ({ userRoles, navItems }) => (
  <>
    <Toolbar data-testid="app-header">
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
      >
        <Link to="/" sx={{ textDecoration: 'none' }}>
          Ford <strong>{APP_NAME}</strong>
        </Link>
      </Typography>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Nav userRoles={userRoles} navItems={navItems} testId="header-nav" />
      </Box>
    </Toolbar>
    <Divider />
  </>
);

Header.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  userRoles: PropTypes.arrayOf(PropTypes.string),
};

Header.defaultProps = {
  navItems: [],
  userRoles: [],
};

export default Header;
