import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material';

const CheckboxWithLabel = (props) => {
  const { label, value } = props;

  return (
    <FormControlLabel
      control={<Checkbox {...props} defaultChecked={value} />}
      label={label}
    />
  );
};

CheckboxWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

export default CheckboxWithLabel;
