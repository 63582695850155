import { Button } from '@mui/material';

import PageContent from '../../../components/PageContent';
import LeadList from '../../../components/Leads/List';

import { FORD_ADMIN_ROLE, PATHS } from '../../../constants';

const Leads = () => (
  <PageContent title="Leads" fullWidth testId="leads-list-page">
    {window.userRoles.includes(FORD_ADMIN_ROLE) && (
      <Button
        variant="text"
        href={`${PATHS.LEADS}/${PATHS.LEADS_NEW}`}
        sx={{ float: 'right' }}
      >
        Add Lead
      </Button>
    )}
    <div style={{ clear: 'both', marginTop: '3rem' }}>
      <LeadList />
    </div>
  </PageContent>
);

export default Leads;
