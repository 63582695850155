import { Container } from '@mui/material';

import './Footer.scss';

const Footer = () => (
  <Container component="footer" data-testid="app-footer">
    <p>&copy; {new Date().getFullYear()} Ford Motor Company</p>
  </Container>
);

export default Footer;
